/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useEffect, useContext, useState, useRef } from "react"
import { dashboardTrail } from "../../utils/dashboardBreadcrumb"
import LayoutDashborad from "../../components/Layout/Dashboard"
import BreadCrumb from "../../components/Elements/BreadCrumb"
import { parse } from "query-string"
import DownloadTable from "../../components/Table/MyListing/AssetsTable"
import {
  deleteMySingleForm,
  getBrandSingle,
  getMyAssets,
  getMyListing,
  getMyListingPagination,
  getMySingleForm,
  getMyListingPost,
} from "../../helpers/DrupalHelper"
import StateContext from "../../context/state/StateContext"
import AssetsTable from "../../components/Table/MyListing/AssetsTable"
import DynamicForm from "../../components/Forms/DynamicForm/DynamicForm"
import EditFormTemplate from "../../components/Forms/EditFormTemplate"
import Modal from "../../components/Modal/Modal"
import SectionCard from "../../components/Cards/Dashboard/SectionCard"
import Button from "../../components/Elements/Button"
import NotificationContext from "../../context/Notification/NotificationContext"
import TableFilter from "../../components/Table/TableFilter"
import Skeleton from "../../components/Skeleton"
import Pagination from "../../components/Pagination"
import AuthenticationContext from "../../context/authentication/AuthenticationContext"
import AccessDenied from "../../components/AccessDenied"
import { navigate } from "gatsby"
import CreateAsset from "../../components/Forms/CreateAsset"
import InventorySubmission from "../../components/InventorySubmission"

export const FloatingOption = ({ selectedItems }) => {
  const [isOptionModalShow, setIsOptionModalShow] = useState(false)
  const options = [
    "Permanently delete",
    "Add to inventory submission",
    "Add user segments",
    "Remove user segments",
    "Adjust allocations",
    "Adjust order limits",
    "Add to group",
    "Remove from group",
    "Adjust cost",
    "Adjust price",
    "Change category",
    "Add tags",
    "Remove tags",
  ]
  return (
    <div className="">
      <div className="bg-[#b7b7b7] relative flex items-center px-4 py-2 w-fit rounded-lg gap-x-3 border border-white shadow-md mx-auto my-0 ">
        <Button
          widthAuto
          className={
            "!bg-white !text-[#595959] !border-[#dedede] hover:!bg-[#f5f5f5] hover:!text-[#7b7b7b]"
          }
        >
          Publish
        </Button>
        <Button
          widthAuto
          className={
            "!bg-white !text-[#595959] !border-[#dedede] hover:!bg-[#f5f5f5] hover:!text-[#7b7b7b]"
          }
        >
          Unpublish
        </Button>
        <Button
          widthAuto
          className={
            "!bg-white !text-[#595959] !border-[#dedede] hover:!bg-[#f5f5f5] hover:!text-[#7b7b7b]"
          }
          onClick={() => {
            setIsOptionModalShow(!isOptionModalShow)
          }}
        >
          ...
        </Button>

        <div className="absolute top-[-394px] right-0">
          {isOptionModalShow && (
            <div className="px-3 pt-5 bg-white shadow-md rounded-xl w-fit h-[390px] overflow-y-auto">
              <span className="!font-semibold text-med-16 !text-[#333333]">
                Edit {selectedItems?.id} Address
              </span>
              <div className="">
                {options.map((item, k) => {
                  return (
                    <div key={k}>
                      <div
                        className={`py-5 pr-20 border-b-2 ${options.length === k + 1 && "border-none"
                          }`}
                      >
                        <span className="text-reg-15 hover:text-[#009d4f] hover hover-green hover-lh-1 hover-thin cursor-pointer transition-all ml-3">
                          {item}
                        </span>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

function MyAssets({ location }) {
  const { authentication, userType } = useContext(AuthenticationContext)

  const { state } = useContext(StateContext)
  const [list, setList] = useState(null)
  const [open, setOpen] = useState(false)
  const [openAfterSave, setOpenAfterSave] = useState(false)
  const [contentAfterSave, setContentAfterSave] = useState("")
  const [editCurrent, setEditCurrent] = useState(null)
  const [editCurrentBundle, setEditCurrentBundle] = useState(null)
  const [formData, setFormData] = useState(null)
  const [openCreate, setOpenCreate] = useState(false)
  const { toggleNotification } = useContext(NotificationContext)
  const [pageNumber, setPageNumber] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const [total, setTotal] = useState(1)
  const [showPagination, setShowPagination] = useState(true)
  const [perPage, setPerPage] = useState(20)
  const [isOptionShow, setIsOptionShow] = useState(false)
  const [fileData, setFileData] = useState({})

  const [tagsData, setTagsData] = useState()

  const scrollRef = useRef()

  /* Inventory */
  const [inventorySubmissionOpen, setInventorySubmissionOpen] = useState(false)
  const [inventorySubmissionNew, setInventorySubmissionNew] = useState(false)
  const [inventorySubmissionExisting, setInventorySubmissionExisting] =
    useState(false)
  const [submitNewRequest, setSubmitNewRequest] = useState(false)
  const [submitNewRequestAsset, setSubmitNewRequestAsset] = useState(null)
  const [inventoryItemFormValues, setInventoryItemFormValues] = useState({})
  /* Inventory */

  // const [total, setTotal] = useState(0)
  // const [page, setPage] = useState(1)
  // const [totalPage, setTotalPage] = useState(1)
  // const [perPage, setPerPage] = useState(5)

  const closeModal = async (updatedOrSavedEntity = null) => {
    setInventorySubmissionOpen(false)
    if (updatedOrSavedEntity !== null) {
    }
  }

  const updateActiveInventorySubmission = ({
    isNew: isNew = true,
    data: data = {},
  }) => {
    console.log(isNew, "isNew")
    setInventorySubmissionOpen(true)
    setInventoryItemFormValues(data)
    setInventorySubmissionNew(isNew)
  }

  const onAddClick = v => {
    console.log("onaddclick", v, 'vvvvvvv§')
    let f = list.filter(c => c.id == v)[0]

    updateActiveInventorySubmission({
      isNew: true,
      data: {
        assets: [
          {
            asset: {
              value: v.id,
              label: v.title,
            },
            brand: {
              value: v.field_asset_brand[0].target_id,
              label: v.field_asset_brand[0].title,
            },
          },
        ],
        company: {
          id: v.field_asset_company[0].target_id,
        },
      },
    })
    setSubmitNewRequestAsset(f)
    setSubmitNewRequest(true)
  }

  // const [modifiedData, setModifiedData] = useState([])

  // useEffect(() => {
  //   setModifiedData(
  //     list?.map(item => {
  //       return {
  //         ...item,
  //         checked: false,
  //       }
  //     })
  //   )
  // }, [list])

  // console.log(tagsData, "tagData")
  console.log({ editCurrentBundle })
  const [body, setBody] = useState({ title: "", brand: null, status: null })
  // useEffect(async () => {
  // let total = await getMyListingPagination(state.token, "asset", "all")
  // setTotal(total)

  // let pages = Math.ceil(total / perPage)
  // setTotalPage(pages)
  // }, [])

  useEffect(() => {
    let pages = Math.ceil(total / perPage)
    setTotalPage(pages)
  }, [perPage])

  const onPageChange = async v => {

    seLoader(true)
    setPageNumber(v)
    const newPageData = await getMyListing(state.token, "asset", "all", v)
    setList([...newPageData.data])
    setTotal(newPageData.count)
    setTotalPage(Math.ceil(newPageData.count / perPage))
    seLoader(false)
  }

  const onEditClick = (v, bundle) => {
    setEditCurrent(v)
    setEditCurrentBundle(bundle)
    setOpen(true)
  }
  const onSave = async (data, v) => {
    if (v === "added") {
      setOpenCreate(false)
      // let data = await getMyListing(state.token, "asset", "all")
      // setList(data)
      const datas = await getMyListingPost(state.token, "asset", body, "all")
      setList(datas.data)
      setTotal(datas.count)
      setTotalPage(Math.ceil(datas.count / perPage))
      toggleNotification({ content: "Asset Added Sucessfully" })
    } else if (v === "edited") {
      setOpen(false)
      setEditCurrent(null)
      // let data = await getMyListing(state.token, "asset", "all")
      // setList(data)
      const datas = await getMyListingPost(state.token, "asset", body, "all")
      setList(datas.data)
      setTotal(datas.count)
      setTotalPage(Math.ceil(datas.count / perPage))
      toggleNotification({ content: "Asset Edited  Sucessfully" })
    } else {
      setOpen(false)
      setEditCurrent(null)
      toggleNotification({ content: data + " Failed", error: true })
    }
  }
  useEffect(async () => {
    if (location.search) {
      let { p } = parse(location.search)
      if (p) onPageChange(parseInt(p))
    } else {
      const data = await getMyListing(state.token, "asset", "all")
      setList(data.data)
      setTotal(data.count)
      setTotalPage(Math.ceil(data.count / perPage))
    }
    if (location?.state?.asset_id) {
      console.log(location.state)
      setEditCurrent(location.state.asset_id)
      setEditCurrentBundle("default")
      setOpen(true)
    }
    console.log(location.state)
    let formData = await getMySingleForm(state.token, "asset")
    setFormData(formData)
  }, [])
  console.log(state.token)
  const loadRef = useRef()
  const [hasMore, setHasMore] = useState(false)
  const [loader, seLoader] = useState(false)

  if (!authentication.currentUserData.companies.length) {
    navigate("/company-status")
  }

  if (!userType.brandManager) return <AccessDenied />

  // const isOptionShow = modifiedData?.some(item => item.checked === true)
  // const selectedItems = modifiedData?.filter(item => item.checked === true)
  // console.log(modifiedData)
  // console.log(selectedItems)

  const tableFilterOnChange = (v) => {
    setList(v.data)
    setTotal(v.count)
    setTotalPage(Math.ceil(v.count / perPage))
  }

  return (
    <>
      <InventorySubmission
        enterFormValues={inventoryItemFormValues}
        closeModal={closeModal}
        inventorySubmissionNew={inventorySubmissionNew}
        inventorySubmissionOpen={inventorySubmissionOpen}
      />
      <h1 className="text-med-20">
        Create and edit digital and physical assets for your brands.
      </h1>
      <LayoutDashborad
        heading={"My Assets"}
        // subheading={
        //   "Create and edit digital and physical assets for your brands."
        // }
        trail={dashboardTrail}
      >
        <div className="mb-[30px]">
          <Button red widthAuto onClick={() => setOpenCreate(true)}>
            Create New Asset
          </Button>
        </div>
        <TableFilter
          setPageNumber={setPageNumber}
          onChange={tableFilterOnChange}
          list={list}
          setShowPagination={setShowPagination}
          name="asset"
          bundle={"all"}
          loadRef={loadRef}
          setHasMore={setHasMore}
          setBody={setBody}
          seLoader={seLoader}
        />
        <div ref={scrollRef}></div>
        {list === null || loader === true ? (
          <Skeleton table count={20} />
        ) : list.length > 0 ? (
          <>
            <span className="text-med-14"

            >{`Showing ${list.length} results`}</span>
            <div className="mt-2">
              <AssetsTable
                // isOptionShow={isOptionShow}
                // setModifiedData={setModifiedData}
                // modifiedData={modifiedData}
                // selectedItems={selectedItems}
                addInventory={onAddClick}
                setFileData={setFileData}
                fileData={fileData}
                setIsOptionShow={setIsOptionShow}
                isOptionShow={isOptionShow}
                data={list}
                onEditClick={onEditClick}
                onDeleteClick={async (id, bundle) => {
                  let res = await deleteMySingleForm(
                    state.token,
                    "asset",
                    bundle,
                    id
                  )
                  console.log(res)
                  if (res.ok) {
                    toggleNotification({ content: id + " Deleted Sucessfully" })
                    // let data = await getMyListing(state.token, "asset", "all")
                    // setList(data)
                    const datas = await getMyListingPost(
                      state.token,
                      "asset",
                      body,
                      "all"
                    )
                    setList(datas.data)
                    setTotal(datas.count)
                    setTotalPage(Math.ceil(datas.count / perPage))
                  } else {
                    toggleNotification({
                      content: id + " Failed to delete ",
                      error: true,
                    })
                  }
                }}
              />
            </div>
          </>
        ) : (
          "No items to display"
        )}
        <div ref={loadRef} />
        {showPagination ? (
          <Pagination
            scrollRef={scrollRef}
            page={pageNumber}
            setPage={v => onPageChange(v)}
            totalPages={totalPage}
          />
        ) : hasMore ? (
          <div className="flex flex-col items-center justify-center w-full overflow-hidden">
            <div className="w-8 h-8 ease-linear border-2 border-t-2 border-gray-200 rounded-full loader"></div>
          </div>
        ) : (
          !loader && <p className="text-reg-14">No more results to display</p>
        )}
        {
          // <Modal
          //   title={"Create New Asset"}
          //   md
          //   isOpen={openCreate}
          //   closeModal={() => {
          //     setOpenCreate(false)
          //   }}
          // >
          //   {formData !== null && (
          //     <DynamicForm
          //       onSave={onSave}
          //       addNew={true}
          //       data={formData}
          //       token={state.token}
          //       name="asset"
          //       bundle="default"
          //     />
          //   )}
          // </Modal>
        }
        <Modal
          title={"Create New Asset"}
          md
          isOpen={openCreate}
          closeModal={() => {
            setOpenCreate(false)
          }}
        >
          {formData !== null && (
            <CreateAsset
              onSave={onSave}
              addInventory={onAddClick}
            />
          )}
        </Modal>
        {/* <SectionCard title={"Create New Assets"} id="create">

      </SectionCard> */}
        {open && editCurrent && (
          <Modal
            md
            isOpen={open}
            closeModal={() => {
              setEditCurrent(null)
              setOpen(false)
            }}
            title={"Edit Asset"}
          >
            {formData !== null && (
              <CreateAsset
                edit
                id={editCurrent}
                editCurrentBundle={editCurrentBundle}
                onSave={onSave}
                list={list}
                disableVariantField={true}
                addInventory={onAddClick}
              />
            )}
            {
              // <EditFormTemplate
              //   edit
              //   id={editCurrent}
              //   token={state.token}
              //   bundle={editCurrentBundle}
              //   name={"asset"}
              //   list={list}
              //   onSave={onSave}
              // />
            }
          </Modal>
        )}
        {openAfterSave && (
          <Modal
            md
            isOpen={openAfterSave}
            closeModal={() => {
              setOpenAfterSave(false)
            }}
          >
            <h2>{contentAfterSave}</h2>
          </Modal>
        )}
        {/* {isOptionShow && (
        <div className="sticky block max-md:hidden bottom-2">
          <FloatingOption selectedItems={fileData} />
        </div>
      )} */}
      </LayoutDashborad>
    </>
  )
}

export default MyAssets
